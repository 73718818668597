/**
 * WordPress dependencies
 */
import { SVG, Path } from "@wordpress/primitives";
import React from "react";

const help = (
    <SVG width={7.734} height={7.342} viewBox="0 0 7.734 7.342">
        <Path
            d="M.75 2.697C.75 1.622 2.15.75 3.867.75s3.117.872 3.117 1.948-1.396 1.951-3.117 1.951v1.947"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        />
    </SVG>
);

export default help;
