/**
 * WordPress dependencies
 */
 import { SVG, Path } from '@wordpress/primitives';

 const arrowIcon = (
  <SVG
  width={9.578}
  height={5.783}
  viewBox="0 0 9.578 5.783"
>
  <Path
    d="M4.82 5.783a.963.963 0 01-.684-.283L.283 1.651A.967.967 0 011.651.283L4.82 3.462 7.988.293a.963.963 0 011.358 1.358L5.494 5.5a.963.963 0 01-.674.283z"
    fill="#141516"
  />
</SVG>
 );
 
 export default arrowIcon;
 